<template>
  <Transition name="slide">
    <Alert
      v-model="alertBanner.state.value.active"
      class=""
      v-bind="alertBanner.state.value.props"
      @close="alertBanner.close"
      @confirm="alertBanner.confirm" />
  </Transition>
</template>

<script setup lang="ts">
import { useAlertBanner } from '~/composables/shared/useAlertBanner'

const alertBanner = useAlertBanner()
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  @apply transition-all duration-300 ease-in-out;
}

.slide-enter-from {
  @apply h-0 overflow-hidden p-0;
}

.slide-enter-to {
  @apply h-12;
}

.slide-leave-from {
  @apply h-12;
}

.slide-leave-to {
  @apply h-0 overflow-hidden p-0;
}
</style>
